<template>
    <modal show="show" :modalLarge="true">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">View Document</h6>
                <button type="button" class="btn btn-primary" v-on:click="printInvoice()">&nbsp;&nbsp;&nbsp;&nbsp;
                    Print</button>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body text-center"  id='voucherInvoice'>
                <div >
                    <!-- <img src="header.png" style="width: 100%;height:90px"> -->
                    <HeaderComponent></HeaderComponent>
                </div>
                <div class="row">
                    <div class="col-md-12 col-12 "
                    style="border:2px solid;border-color:black !important;background-color:white">
                    <div class="row mt-1" style="margin-bottom:10px !important;height:10mm;background-color:white">
                        <div class="col-md-12">
                            <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankReceipt' && list.paymentMode == 0">Customer Cash Receipt</span>
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankReceipt' && list.paymentMode == 1">Customer Bank Receipt</span>
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankPay' && list.paymentMode == 0">Supplier Cash Pay</span>
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankPay' && list.paymentMode == 1">Supplier Bank Pay</span>
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankPay' && list.paymentMode == 5">Supplier Bank Pay</span>
                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'BankReceipt' && list.paymentMode == 5">Customer Bank Receipt</span>

                                <span
                                    style="font-size:18px;color:black !important;font-weight:bold;padding-bottom:5px !important"
                                    v-if="formName == 'PettyCash'">Petty Cash</span>

                            </p>
                        </div>
                    </div>
                    <div class="row mt-1" style="background-color: white">
                        <div class="col-md-6 col-6" style="display:flex;">
                            <div
                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                Date:</div>
                            <div style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                {{ list.dates }}
                                <hr
                                    style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;padding-left:6px !important;padding-right:6px !important" />
                            </div>
                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-if="arabic == 'true'">:تاريخ</div>
                        </div>
                        <div class="col-md-6 col-6" style="display:flex;">
                            <div
                                style="width:28%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                Voucher No:</div>
                            <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">
                                {{ list.voucherNumber }}
                                <hr
                                    style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;padding-left:6px !important;padding-right:6px !important" />
                            </div>
                            <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important"
                                v-if="arabic == 'true'">
                                :رقم القسيمة
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1 mb-1" style="background-color: white">
                        <div class="col-md-2 col-2">
                            <div
                                style="font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                Mr:</div>

                        </div>
                        <div class="col-md-8 col-8">
                            <div
                                style="font-weight:bolder;text-align:center;color:black !important;font-size:14px !important;padding-bottom:0px !important;margin-bottom:0px !important;">
                                {{ list.contactAccountName }}</div>
                            <hr
                                style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;" />
                        </div>
                        <div class="col-md-2 col-2">
                            <div style="font-weight:bolder;text-align:left;color:black !important;font-size:14px !important;padding-left:30px"
                                v-if="arabic == 'true'">
                                :/استلمنا من


                            </div>
                        </div>

                    </div>
                    <div class="row mt-1 mb-1" style="background-color: white">
                        <div class="col-md-2 col-2">
                            <div
                                style="font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                Sum of S.R:</div>

                        </div>
                        <div class="col-md-8 col-8">
                            <div
                                style="font-weight:bolder;text-align:center;color:black !important;font-size:14px !important;padding-bottom:0px !important;margin-bottom:0px !important;">
                                {{ currency }}
                                {{ parseFloat(calulateTotalAmount).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                    "$1,") }}</div>
                            <hr
                                style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;" />
                        </div>
                        <div class="col-md-2 col-2" v-if="arabic == 'true'">
                            <div
                                style="font-weight:bolder;text-align:left;color:black !important;font-size:14px !important;padding-left:30px">
                                : المبلغ

                            </div>
                        </div>

                    </div>
                    <div class="row mt-1 mb-1" style="background-color: white">
                        <div class="col-md-6 col-6" style="display:flex;">
                            <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important"
                                v-if="list.paymentMode == 0">Cash Account:</div>
                            <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important"
                                v-else-if="list.paymentMode == 1">Bank Account:</div>
                            <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important"
                                v-else-if="list.paymentMode == 5">Advance Bank Account:</div>
                            <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important"
                                v-else>Bank Account:</div>
                            <div style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                {{ list.bankCashAccountName }}
                                <hr
                                    style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;" />
                            </div>
                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-if="list.paymentMode == 0 && arabic == 'true'">:حساب نقدي</div>
                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-else-if="list.paymentMode == 1 && arabic == 'true'">:حساب البنك</div>
                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-else-if="list.paymentMode == 5 && arabic == 'true'">:الحساب المصرفي المسبق</div>

                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-else-if="arabic == 'true'">:حساب البنك</div>
                        </div>
                        <div class="col-md-6 col-6 mt-1" style="display:flex;" v-if="list.paymentMethod == 1">
                            <div
                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                Cheque:</div>
                            <div style="width:50%; text-align:center;color:black !important;font-weight:bold;">
                                {{ list.chequeNumber }}
                                <hr
                                    style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;" />
                            </div>
                            <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;"
                                v-if="arabic == 'true'">:التحقق من</div>
                        </div>
                        <div class="col-md-6 col-6" style="display:flex;padding-top:25px">
                            <div
                                style="width:28%;font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;padding-right:5px">
                                Received by</div>
                            <div
                                style="width:50%; text-align:center;color:black !important;font-weight:bold;padding-top:18px">

                                <hr
                                    style="border:1px dashed ; border-color:black !important;padding-top:0px !important;margin-top:0px !important;" />
                            </div>

                        </div>

                    </div>
                    <div class="row mt-1 mb-1" style="background-color: white">
                        <div class="col-md-2 col-2">
                            <div
                                style="font-weight:bolder;text-align:right;color:black !important;font-size:14px !important">
                                For:</div>

                        </div>
                        <div class="col-md-8 col-8">
                            <div style="font-weight:bolder;text-align:center;color:black !important;font-size:14px !important;padding-bottom:0px !important;margin-bottom:0px !important; text-decoration-line: underline;text-decoration-style: dashed;"
                                class="text-justify">{{ list.narration }}</div>
                            <!--<hr v-if="list.narration==''" style="border:1px dashed ; border-color:black !important;padding-top:5px !important;" />-->
                        </div>
                        <div class="col-md-2 col-2">
                            <div style="font-weight:bolder;text-align:left;color:black !important;font-size:14px !important;padding-left:30px"
                                v-if="arabic == 'true'">
                                : وذلك مقابل


                            </div>
                        </div>

                    </div>
                    <div class="row mt-2" style="background-color: white">
                        <div class="col-md-2 col-2">

                        </div>
                        <div class="col-md-3 col-3">
                            <vue-qrcode v-bind:value="qrValue" style="width:140px;" />
                        </div>
                        <div class="col-md-7 col-7">
                            <table class="table ">
                                <tr>
                                    <td
                                        style="width:30%;font-size:14px;font-weight:bold; border:1px solid black !important;color:black !important;text-align:right;border-color:black !important">
                                        Running Balance:</td>
                                    <td
                                        style="width: 40%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: right; border-color: black !important">
                                        {{ runningBalance }} </td>
                                    <td style="width: 30%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: left; border-color: black !important"
                                        v-if="arabic == 'true'">الميزان الجاري </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:30%;font-size:14px;font-weight:bold; border:1px solid black !important;color:black !important;text-align:right;border-color:black !important">
                                        Balance:</td>
                                    <td
                                        style="width: 40%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: right; border-color: black !important">
                                        {{ parseFloat(calulateTotalAmount).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }} </td>
                                    <td style="width: 30%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: left; border-color: black !important"
                                        v-if="arabic == 'true'">الرصيد </td>
                                </tr>
                                <tr>
                                    <td
                                        style="width:30%;font-size:14px;font-weight:bold; border:1px solid black !important;color:black !important;text-align:right;border-color:black !important">
                                        Closing Balance:</td>
                                    <td
                                        style="width: 40%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: right; border-color: black !important">
                                        {{ closingBalance }} </td>
                                    <td style="width: 30%; font-size: 14px; font-weight: bold; border: 1px solid black !important; color: black !important; text-align: left; border-color: black !important"
                                        v-if="arabic == 'true'">الرصيد الختامي </td>
                                </tr>

                            </table>

                        </div>

                    </div>





                </div>
                </div>
               


            </div>

        </div>

    </modal>
</template>
<script>
    import moment from "moment";
    import VueQrcode from 'vue-qrcode'

    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            './assets/css/A5Print2.css' // <- inject here
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        components: {
            VueQrcode,
        },
        props: ['formName', 'printId', 'headerFooter'],
        data: function () {
            return {
                qrValue: "",
                isHeaderFooter: '',
                closingBalance: '',
                runningBalance: '',
                balance: '',
                invoicePrint: '',
                arabic: '',
                english: '',
                list: '',
                render: 0,
                currency: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                }

            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalAmount: function () {
                return this.list.amount;
            }
        },
        mounted: function () {
            debugger;

            this.currency = localStorage.getItem('currency');
            this.headerFooters = this.headerFooter;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.invoicePrint = localStorage.getItem('InvoicePrint');

            var root = this;
            if (this.printId != '00000000-0000-0000-0000-000000000000') {
                root.getDetails();
            }
        },
        methods: {
            close: function () {
            this.$emit('close');
        },

            GetBankOpeningBalance: function (id,date) {

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var root = this
                this.$https.get('/Contact/GetCustomerRunningBalance?id=' + id + '&date=' + date, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.closingBalance = parseFloat(response.data) >= 0 ? 'Dr ' + parseFloat(response.data) * +1 : 'Cr ' + parseFloat(response.data) * (-1);
                        if (parseFloat(response.data) >= 0) {
                            root.runningBalance = parseFloat(response.data) + root.list.amount;
                            root.runningBalance = parseFloat(root.runningBalance) >= 0 ? 'Dr ' + parseFloat(root.runningBalance) * +1 : 'Cr ' + parseFloat(root.runningBalance) * (-1);
                        }
                        else {
                            root.runningBalance = parseFloat(response.data) - root.list.amount;
                            root.runningBalance = parseFloat(root.runningBalance) >= 0 ? 'Dr ' + parseFloat(root.runningBalance) * +1 : 'Cr ' + parseFloat(root.runningBalance) * (-1);

                        }
                    }
                });


            },
            convertMainDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            paymentMode: function (x) {
                if (this.invoicePrint == 'العربية') {

                    if (x == 0) {
                        return 'السيولة النقدية';
                    }
                    if (x == 1) {
                        return 'مصرف';
                    }
                    if (x == 5) {
                        return 'يتقدم';
                    }



                }
                else {

                    if (x == 0) {
                        return 'Cash';
                    }
                    if (x == 1) {
                        return 'Bank';
                    } if (x == 5) {
                        return 'Advance';
                    }

                }
            },
            paymentMethod: function (x) {
                if (this.invoicePrint == 'العربية') {
                    if (x == 1) {
                        return 'التحقق من';
                    }
                    else if (x == 2) {
                        return 'تحويل';
                    }
                    else if (x == 3) {
                        return 'الوديعة';
                    }
                    else {
                        return '';
                    }


                }
                else {
                    if (x == 1) {
                        return 'Cheque';
                    }
                    else if (x == 2) {
                        return 'Transfer';
                    }
                    else if (x == 3) {
                        return 'Deposit';
                    }
                    else {
                        return '';
                    }


                }
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            pettyCash: function (x) {
                if (this.invoicePrint == 'العربية') {
                    if (x == 1) {
                        return 'مؤقت';
                    }
                    else if (x == 2) {
                        return 'عام';
                    }
                    else if (x == 3) {
                        return 'تقدم';
                    }



                }
                else {
                    if (x == 1) {
                        return 'Temporary';
                    }
                    else if (x == 2) {
                        return 'General';
                    }
                    else if (x == 3) {
                        return 'Advance';
                    }



                }
            },

            getDetails: function () {

                var Id = this.printId;
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/PaymentVoucher/PaymentVoucherDetails?id=" + Id, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {

                            let list = response.data;

                            root.list=list;
                            root.GetBankOpeningBalance(root.list.contactAccountId,root.list.date);
                            root.qrValue = 'Voucher No :' + root.list.voucherNumber + '\nAmount:' + root.list.amount;

                            //setTimeout(function () {
                            //    root.printInvoice();
                            //}, 125)
                        }
                    });
            },
            printInvoice: function () {
                this.$htmlToPaper('voucherInvoice', options, () => {


                    console.log('No Rander the Page');


                });

            }
        }
    }
</script>


<style scoped>
</style>